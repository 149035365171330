footer {
  border-bottom: $a1 solid #eaecef;
  margin-bottom: $a40;
  z-index: 2;
  position: relative;
  margin-top: -$a20;
  a {
    transition: all 0.5s;
    &:hover {
      color: $osn-col1 !important;
    }
  }
  .contacts {
    padding-top: $a120;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $shaded;
    border-bottom: $a1 solid rgba(0,0,0,0.1);
    position: relative;
    z-index: 1;
    a {
      transition: all 0.5s;
      &:hover {
        color: $osn-col1 !important;
      }
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding-top: $a50;
      padding-bottom: 0;
      .perspective {
        width: 31.2%;
      }
      .item {
        border-radius: $a50;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px $a8 $a32 0px rgba(229, 234, 236, 0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.5s;
        transform: translateZ($a184) scale(.9);
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: $a30 0;
        &.new_version {
          border-radius: $a3;
        }
        &:nth-of-type(2) {
          transform: rotateY(270deg) translateZ($a184) scale(.9);
        }
        &:hover {
          z-index: 1;
          .address {
            color: $osn-col1 !important;
          }
        }
        img {
          width: $a50;
        }
        .name {
          font-weight: bold;
          font-size: $a20;
          color: #32313c;
          line-height: $a32;
          transition: all 0.5s;
          margin-top: $a35;
        }
        .address {
          color: #5d5c65;
          font-size: $a14;
          white-space: pre-line;
          line-height: $a26;
          text-align: center;
          transition: all 0.5s;
        }
      }
    }
    .button2 {
      margin-top: $a50;
      margin-bottom: $a100;
    }
  }
  .map_site {
    background: $shaded;
    .container {
      color: #32313c;
      padding-bottom: $a95;
      padding-top: $a55;
      flex-direction: column;
      .top, .bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
      }
      .bottom {
        margin-top: $a20;
      }
      .links {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        perspective-origin: center center;
        justify-content: space-between;
        .title {
          font-weight: bold;
          font-size: $a25;
          padding-bottom: $a15;
          color: #32313c;
          width: 100%;
          height : $a52;
          box-sizing : border-box;
        }
        a {
          font-size: $a14;
          color: #32313c;
          margin-bottom: $a15;
        }
        div:not(.cube) {
          display: flex;
          flex-direction: column;
        }
        &.markets, &.product {
          box-sizing: border-box;
          padding-right: $a50;
          width: 60%;
          div {
            width: (100%/3);
          }
        }
        &.product {
          div:nth-of-type(2) {
            position: relative;
            &:before {
              content: '';
              height: 80%;
              position: absolute;
              left: -$a30;
              top: 50%;
              transform: translate(0, -50%);
              width: $a1;
              background: rgba(0, 0, 0, 0.1);
            }
          }
        }
        &.solution, &.blog {
          width: 40%;
        }
        &.blog {
          .perspective {
            height: $a135;
            position: relative;
            width: $a200;
          }
          article {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            background: #cccccc;
            border-radius: $a25;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            &:before {
              content: '';
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: rgba(0,0,0,0.5);
              z-index: 1;
              border-radius: $a25;
              overflow: hidden;
            }
            .bg {
              position: absolute;
              min-height: 100%;
              min-width: 100%;
              height: 100%;
              border-radius: $a25;
              overflow: hidden;
              img {
                min-height: 100%;
                min-width: 100%;
              }
            }
            h4 {
              z-index: 2;
              color: #ffffff;
              text-align: center;
              font-weight: 500;
              width: 90%;
            }
          }
        }
      }
    }
  }
  .logo_social {
    border-top: $a1 solid rgba(0,0,0,0.1);
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $a20 0;
      .logo {
        width: $a207;
        display: flex;
        justify-content: center;
        z-index: 1;
        img, picture {
          width: 100%;
        }
      }
      .social {
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: fit-content;
        position: absolute;
        a {
          margin: 0 $a20;
          svg {
            height: $a20;
            path {
              transition: all 0.5s;
            }
          }
          &.twitter {
            &:hover {
              svg {
                path {
                  fill: #41abe1;
                }
              }
            }
          }
          &.facebook {
            &:hover {
              svg {
                path {
                  fill: #4267b2;
                }
              }
            }
          }
          &.youtube {
            &:hover {
              svg {
                path {
                  fill: red;
                }
              }
            }
          }
        }
      }
      .developer {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          color: #000000;
        }
        .logo_dev {
          filter: invert(1);
          width: $a150;
          background-image: url(/img/logo_niklex.png);
          background-size: cover;
          transition: all 0.5s;
          display: flex;
          align-items: flex-start;
          img, picture {
            opacity: 0;
            width: 100%;
          }
        }
      }
    }
  }
  .under {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $a40;
    background: $shaded;
    color: #a9a9a9;
    font-size: $a14;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-top: $a1 solid rgba(0,0,0,0.1);
    a {
      color: #a9a9a9;
      margin: 0 $a100;
      text-transform: capitalize;
    }
    p {
      white-space: normal;
    }
  }
}

@media screen and (max-width: $mobi_start) {
  footer {
    z-index: 3;
    margin-top: 0;
    .contacts {
      display: none;
    }
    .map_site {
      padding-top: $m30;
      .container {
        flex-direction: column;
        padding-bottom: $m10;
        .item {
          width: 100%;
          height: $m200;
          margin-bottom: $m20;
          &:hover {
            transform: none;
          }
          img {
            width: $m40;
            padding-bottom: $m27;
          }
          .name {
            font-size: $m16;
            line-height: initial;
            padding-bottom: $m5;
          }
          .address {
            font-size: $m12;
            line-height: initial;
          }
        }
        &:last-child {
          padding-top: 0;
          padding-bottom: 0;
          .title {
            font-size: $m20 !important;
            line-height: initial !important;
            padding-bottom: $m10 !important;
          }
          a {
            font-size: $m10 !important;
            line-height: $m22 !important;
            margin-bottom: 0;
          }
          .links {
            width: 100% !important;
            padding: 0;
          }
          .top {
            flex-direction: column;
            .links {
              padding-bottom: $m10;
              margin-right: 0 !important;
              max-width: 100% !important;
              &.product {
                div:nth-of-type(2) {
                  &:before {
                    height: 100%;
                  }
                }
              }
            }
          }
          .bottom {
            flex-flow: row wrap;
            .links {
              padding-bottom: $m20;
              margin-right: 0 !important;
              max-width: 100% !important;
              &.two {
                flex-wrap: nowrap;
                width: 61%;
                padding-left: $m26;
                padding-bottom: 0;
                margin-bottom: $m20;
                margin-top: $m34;
              }
              &.product {
                width: 30%;
              }
              &.blog {
                width: 100%;
                justify-content: flex-start;
                .title {
                  padding-bottom: $m20 !important;
                }
                .perspective {
                  width: $m110;
                  height: $m70;
                  &:nth-of-type(2) {
                    margin-right: $m20;
                  }
                  article {
                    width: 100%;
                    height: 100%;
                    transform: translateZ($m55);
                    border-radius: $m15;
                    &:before, picture {
                      border-radius: $m15;
                      overflow: hidden;
                    }
                    h4 {
                      font-size: $m10;
                      padding: 0 $m5;
                    }
                    &:nth-of-type(2) {
                      transform: rotateY(270deg) translateZ($m55);
                    }
                  }
                  &:nth-of-type(1) {
                    margin: 0 $m15 0 $m5;
                  }
                }
              }
            }
          }
        }
      }
    }
    .logo_social {
      .container {
        padding: 0;
        flex-flow: row wrap;
        align-items: flex-end;
        justify-content: center;
        background: #fff;
        .logo {
          width: 50%;
          padding: $m10 0;
          display: flex;
          align-items: center;
          picture {
            width: 100%;
          }
          img {
            width: 70%;
          }
        }
        .social {
          padding: 0;
          width: 50%;
          justify-content: space-around;
          position: static;
          a {
            margin: 0;
            display: flex;
            align-items: flex-start;
            svg {
              height: $m20;
            }
          }
        }
        .developer {
          flex-direction: row;
          justify-content: center;
          width: 100vw;
          margin: 0 (-$m8);
          border-top: $a1 solid rgba(0,0,0,0.1);
          background: $shaded;
          padding: $m5 0;
          .logo_dev {
            width: 25%;
          }
          p {
            font-size: $m10 !important;
            font-weight: bold;
            padding-right: $m10;
          }
        }
      }
    }
    .under {
      font-size: $m12;
      height: auto;
      box-sizing: border-box;
      flex-flow: row wrap;
      a {
        margin: 0;
        width: 50%;
        order: 1;
        text-align: center;
        padding: $m10 0;
        border-bottom: $a1 solid rgba(0,0,0,0.1);
        box-sizing: border-box;
        &:nth-of-type(1) {
          border-right: $a1 solid rgba(0,0,0,0.1);
        }
      }
      p {
        text-align: center !important;
        order: 2;
        padding: $m5 0;
        font-size: $m10 !important;
      }
    }
  }
}
