.big_menu {
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  transition-delay: 0.5s;
  overflow: hidden;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  &.open {
    transition-delay: 0ms;
    width: 100vw;
    .bg {
      transform: skewX(45deg);
      span{
        width: calc(16.6666% + #{$a1});
      }
      .bg2 {
        width: $a615;
        height: auto;
      }
      .bg1 {
        bottom: 0;
      }
    }
    .line {
      opacity: 1;
    }
    nav {
      transform: translateY(0);
      transition-delay: .5s;
      opacity: 1;
    }
    .wraper {
      opacity: 1;
      transition: transform .5s .5s, opacity .3s .5s;
      transform: none !important;
      .big {
        transition-delay: .7s;
      }
      &:nth-child(1) {
        transition-delay: .9s;
      }
    }
    .contact {
      transition-delay: .9s;
      opacity: 1;
      transform: translate(0) !important;
    }
    .company, .name {
      opacity: 1;
      transition: all 0.5s .5s;
    }
  }
  .bg {
    width: 160vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -30%;
    z-index: -2;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s;
    .item {
      transform: skewX(0);
      height: 100%;
      width: 0;
      display: flex;
      transition: all 0.5s ease;
      transition-duration: 700ms;
      transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
      overflow: hidden;
      margin-left: -$a1;
      @for $i from 1 through 6 {
        &:nth-of-type(#{$i}) {
          .grad {
            left: (-100% * ($i - 1));
          }
        }
      }
      .grad {
        content: '';
        width: 160vw;
        height: 100vh;
        background-image: linear-gradient(90deg, #45a3e5 0%, #39ce6e 100%);
        position: fixed;
        top: 0;
        left: 0;
        transform: skewX(0deg);
      }
      &:before {
        transition: all 0.5s ease;
        transition-duration: 700ms;
        transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
      }
    }
  }
  .name {
    writing-mode: vertical-rl;
    font-size: $a200;
    text-transform: uppercase;
    font-weight: 900;
    position: absolute;
    right: $a1620;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $a80;
    box-sizing: border-box;
    opacity: 0;
    transition: all 0.5s;
    svg {
      height: 100%;
    }
  }
  .company {
    font-size: $a200;
    height: $a150;
    text-transform: uppercase;
    font-weight: 900;
    position: absolute;
    bottom: calc(33.33333333333333333% - #{$a82/3});
    right: $a97;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  nav {
    z-index: 1;
    height: 100%;
    width: 100%;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
    .wraper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 25%;
      height: 100%;
      box-sizing: border-box;
      padding-top: $a82;
      border-right: $a2 solid rgba(255,255,255,0.1);
      transition: transform .5s, opacity .3s;
      transform: translateX(-$a50);
      opacity: 0;
      &.one {
        border-left: $a2 solid rgba(255,255,255,0.1);
      }
      &.three {
        transform: translateX($a50);
        padding-top: $a232;
      }
      &>a {
        font-weight: bold;
        font-size: $a24;
        margin-right: auto;
        margin-bottom: $a20;
        transition: all 0.5s;
        border-radius: $a50;
        text-transform: uppercase;
        text-align: left;
        position: relative;
        &:nth-child(1) {
          margin-top: $a150;
        }
        &:before {
          position: absolute;
          left: 0;
          top: 100%;
          height: $a1;
          width: 0;
          background: #ffffff;
          content: '';
          transition: all 0.5s;
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
      .holder_link {
        width: 100%;
        position: relative;
        margin-bottom: $a20;
        display: flex;
        justify-content: flex-start;
        &:nth-child(1) {
          margin-top: $a150;
        }
        &.active {
          a, .a {
            &:before {
              width: 100%;
            }
          }
        }
        &:before {
          position: absolute;
          content: '';
          right: $a20;
          top: 0;
          bottom: 0;
          margin: auto;
          width: $a20;
          height: $a20;
          transform: rotate(45deg);
          border: $a2 solid #fff;
          border-bottom: none;
          border-left: none;
        }
        &>a,  &>.a {
          font-weight: bold;
          font-size: $a24;
          margin-right: auto;
          transition: all 0.5s;
          border-radius: $a50;
          text-transform: uppercase;
          text-align: left;
          position: relative;
          color: #fff;
          &:before {
            position: absolute;
            left: 0;
            top: 100%;
            height: $a1;
            width: 0;
            background: #ffffff;
            content: '';
            transition: all 0.5s;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
      .submenu {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .content {
          overflow: hidden;
          transition: all 0.5s;
          display: flex;
          flex-direction: column;
          align-items: center;
          a {
            font-weight: 200;
            font-size: $a18;
            margin-right: auto;
            margin-bottom: $a20;
            transition: all 0.5s;
            border-radius: $a50;
            text-align: left;
            position: relative;
            &:before {
              position: absolute;
              left: 0;
              top: 100%;
              height: $a1;
              width: 0;
              background: #ffffff;
              content: '';
              transition: all 0.5s;
            }
            &:hover {
              &:before {
                width: 100%;
              }
            }
          }
        }
      }
      .buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 33.3333%;
        border-top: $a2 solid rgba(255,255,255,.1);
        margin-top: auto;
        .button2, .button1 {
          width: $a177;
          height: $a55;
          font-size: $a20;
          margin: 0 $a15;
        }
        .button1 {
          border-color: #ffffff;
          background: none;
          color: #7ac2e5;
          &:hover {
            background: transparent;
            color: #ffffff;
          }
        }
        .button2 {
          background: rgba(255,255,255,0.2);
          color: #ffffff;
          text-shadow: 0 0 $a1 rgba(0,0,0,0.1);
          border: none;
          &:hover {
            color: #7ac2e5;
          }
        }
      }
    }
    .contact {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      box-sizing: border-box;
      padding-top: $a82;
      height: 100%;
      border-right: $a2 solid rgba(255,255,255,0.1);
      transition: transform 0.5s, opacity .3s;
      transform: translateX($a50);
      opacity: 0;
      width: 25%;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: (100%/3);
        border-bottom: $a2 solid rgba(255,255,255,.1);
        .img {
          padding-bottom: $a35;
          display: flex;
          align-items: center;
          img {
            width: $a50;
          }
        }
        .title {
          font-weight: bold;
          font-size: $a20;
          padding-bottom: $a10;
        }
        .text {
          text-align: center;
          line-height: $a30;
          font-size: $a16;
        }
      }
    }
  }
  .mobile_menu {
    display: none;
  }
}

@media screen and (max-width: $mobi_start) {
  .big_menu {
    height: 100%;
    width: 100vw;
    left: 100%;
    transition: all 0.5s;
    overflow: auto;
    justify-content: flex-start;
    .bg {
      width: 100vw;
      display: none;
      background-image: linear-gradient(45deg, #45a3e5 35%, #39ce6e 100%);
      left: 0;
      span {
        display: none;
      }
      .bg1 {
        width: 140%;
        right: -40%;
      }
    }
    &:before {
      position: fixed;
      right: 0;
      top: 0;
      width: $a10;
      height: 100%;
      content: '';
    }
    &.open {
      transition-delay: 0ms;
      width: 100vw;
      left: 0;
      .bg {
        transform: none;
        span{
          display: none;
        }
      }
      nav {
        transform: translateY(0);
        transition-delay: .5s;
        opacity: 1;
      }
      .wraper {
        opacity: 1;
        transition-delay: .5s !important;
      }
    }
    .name, .company {
      display: none;
    }
    nav {
      flex-direction: column;
      justify-content: center;
      padding-bottom: $m20;
      padding-top: $m50;
      height: auto;
      min-height: 100%;
      background-image: linear-gradient(45deg, #45a3e5 35%, #39ce6e 100%);
      box-sizing: border-box;
      .container {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        min-height: 100%;
        .mobile_menu {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          box-sizing: border-box;
          .holder_link {
            margin-bottom: $m5;
            position: relative;
            transition: all 0.5s;
            transition-delay: .5s;
            width: 100%;
            .a {
              margin-bottom: 0;
            }
            &:before {
              background: url('/img/arrow.svg') no-repeat center center / 100% 100%;
              width: $m20;
              height: $m12;
              right: $m10;
              top: 0;
              bottom: 0;
              margin: auto;
              transition: all 0.5s;
              content: '';
              position: absolute;
            }
            &.active {
              &:before {
                transform: rotate(180deg);
              }
              &~a, &~.holder_link {
                opacity: 0;
                transition-delay: 0s;
              }
            }
            .content {
              position: absolute;
              top: 100%;
              left: 0;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              margin-top: $m10;
              z-index: 10;
              a {
                text-transform: capitalize;
                font-weight: 500;
                font-size: $m16;
                padding-bottom: $m5;
                margin-bottom: 0;
                white-space: nowrap;
              }
            }
          }
          a, .a {
            font-size: $m18;
            margin-bottom: $m5;
            font-weight: bold;
            text-transform: uppercase;
            transition: all 0.5s;
            transition-delay: .5s;
            color: #fff;
            &.big {
              font-size: $m24;
            }
            &:after {
              position: absolute;
              left: 0;
              top: 100%;
              height: $a1;
              width: 0;
              background: #ffffff;
              content: '';
              transition: all 0.5s;
            }
          }
          .buttons {
            display: flex;
            justify-content: flex-start;
            border: none;
            position: absolute;
            top: -$m43;
            left: 0;
            width: auto;
            height: auto;
            .button1, .button2 {
              width: auto;
              padding: 0 $m10;
              height: $m25;
              font-size: $m12;
              box-shadow: none;
              margin-right: $m10;
              font-weight: 500;
              text-transform: none;
              &.white {
                background: #fff;
                border-color: $osn-col1;
                color: $osn-col1;
                &:before {
                  display: none;
                }
              }
            }
            .button2 {
              background-color: rgba(255,255,255,.5);
              border-color: #ffffff;
            }
          }
        }
      }
      .wraper {
        width: 100%;
        height: auto;
        padding: 0;
        box-sizing: border-box;
        border: none !important;
        transition-delay: 0s !important;
        opacity: 1 ;
        transform: none !important;
        display: none;
        &.one {

        }
        &.two {
          .buttons {
            left: $m75;
            .button2 {
              border: $a1 solid rgba(255,255,255,0.2);
            }
          }
        }
        .buttons {
          justify-content: flex-start;
          border: none;
          position: absolute;
          top: -$m43;
          left: 0;
          width: auto;
          height: auto;
          .button1, .button2 {
            width: $m65;
            height: $m25;
            font-size: $m12;
            margin: 0;
            box-shadow: none;
            &.white {
              background: #fff;
              &:before {
                display: none;
              }
            }
          }
        }
        &>a {
          font-size: $m18;
          margin: 0 0 $m8 0 !important;
          &.big {
            font-size: $m24;
          }
          &:before {
            bottom: $m1;
          }
        }
        .holder_link {
          margin: 0 0 $m8 0 !important;
          width: 37%;
          &~div, &~a {
            transition-delay: .5s;
            transition: .5s;
          }

          &:before {
            transform: rotate(135deg) translate(0, 45%);
            transform-origin: center center;
            width: $m10;
            height: $m10;
            left: 100%;
            transition: all 0.5s;
          }
          a, .a {
            font-size: $m18;
            margin: 0;
            &.big {
              font-size: $m24;
            }
            &:after {
              position: absolute;
              left: 0;
              top: 100%;
              height: $a1;
              width: 0;
              background: #ffffff;
              content: '';
              transition: all 0.5s;
            }
          }
          &.active {
            &:before {
              transform: rotate(-45deg);
              bottom: -$m8;
            }
            &~div:not(.buttons), &~a {
              opacity: 0;
              transition-delay: 0s;
            }
            a {
              &:before {
                width: 0;
              }
              &:after {
                width: 100%;
              }
            }
          }
        }
        &.three {
          position: absolute;
          top: $m245;
          padding-top: 0;
          .submenu {
            display: none;
            &.product {
              margin-top: -$m30;
            }
            &.markets {
              margin-top: -$m60;
            }
            .content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              a {
                margin: 0 0 $m5 0;
                font-size: $m12;
              }
            }
          }
        }
      }
      .contact {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        align-self: flex-end;
        order: 5;
        width: 100%;
        height: auto;
        padding-top: $m20;
        box-sizing: border-box;
        border: none;
        transition-delay: 0s;
        opacity: 1 ;
        transform: none;
        margin-top: auto;
        .item {
          width: $m40;
          height: $m40;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          background: rgba(0,0,0,0.2);
          border-radius: 50%;
          margin: 0 $m10 0 0;
          .img {
            padding: 0;
            img {
              width: $m15;
            }
          }
          &:nth-child(1) {
            order: 1;
          }
          .all {
            order: 2;
          }
          .title {
            font-size: $m10;
            display: none;
          }
          .text {
            height: 0;
            overflow: hidden;
          }
        }
      }
    }
  }
}
