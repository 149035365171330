header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: $a1180;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: $a15 $a1 $a15 0;
  z-index: 100;
  transition:all .5s, border .5s linear 0.5s;
  box-sizing: border-box;
  &:before {
    content: '';
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 0;
    border-bottom: $a1 solid rgba(255,255,255,.1);
    transition: all 0.5s;
    transition-delay: .5s;
  }
  .logo {
    height: $a52;
    width: $a161;
    background-image: url(/img/logo.png), url(/img/logo_color.png);
    transition: all 0.5s;
    background-size: contain;
    background-repeat : no-repeat;
    background-position : center center;
    img {
      display: none;
    }
    &:hover {
      background-image: url(/img/logo_color.png);
    }
  }
  nav {
    height: 100%;
    margin-left: auto;
    .menu {
      display: flex;
      align-items: stretch;
      height: 100%;
      margin-left: auto;
      transition: all 0.5s;
      transition-delay: .5s;
      li {
        margin: 0 $a5;
        transition: all 0.3s;
        border-radius: $a50;
        z-index: 1;
        &.solution, &.markets {
          &>a, &>.a {
            &:before {
              content: '';
              position: absolute;
              top: 100%;
              width: 0;
              left: 0;
              right: 0;
              margin: auto;
              transition: all 0.5s;
              border: $a10 solid transparent;
              border-bottom-color: #ffffff;
              opacity: 0;
              filter: drop-shadow(0 -$a1 $a1 rgba(0,0,0,0.3));
            }
          }
        }
        a, .a {
          border-radius: $a50;
          transition: all 0.5s;
          padding: $a16 $a20;
          box-sizing: border-box;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          cursor: pointer;
          &:after {
            content: '';
            background: rgba(0,0,0,0.2);
            width: 0;
            height: 0;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            transition: all 0.5s;
            position: absolute;
            z-index: -2;
            border-radius: $a50;
          }
        }
        .open {
          position: absolute;
          top: calc(100% - #{$a15});
          z-index: -1;
          left: 0;
          right: 0;
          width: $a1133;
          margin: auto;
          border-radius: $a25;
          filter: drop-shadow(0 $a1 $a2 rgba(0,0,0,0.5));
          max-width: 98%;
          padding-top: $a20 !important;
          .holder {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.5s;
            transition-delay: .2s;
            background: #ffffff;
            padding: $a20 0;
            border-radius: $a25;
            .holder_item {
              display: flex;
              align-items: center;
              justify-content: center;
              .item {
                width: $a375;
                box-sizing: border-box;
                padding: $a20;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background: none;
                transition: all 0.5s;
                &:after {
                  display: none;
                }
                &:nth-of-type(2n-1) {
                  /*border-right: $a1 solid #ccc;*/
                }
                .img {
                  min-width: $a60;
                  width: $a60;
                  height: $a60;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: $a20;
                  border-radius: 50%;
                  background: #fafaff;
                  box-shadow: $a5 $a5 $a5 rgba(0,0,0,0.5);
                  transition: all 0.5s;
                  img {
                    max-width: 60%;
                    max-height: 60%;
                  }
                }
                .text {
                  h4 {
                    color: $osn-col1;
                    font-size: $a18;
                  }
                  p {
                    font-size: $a12;
                    padding-top: $a5;
                    color: rgba(0,0,0,0.6);
                    font-weight: 500;
                  }
                }
                &:hover {
                  box-shadow: 0 0 $a3 rgba(0,0,0,0.5);
                  .img {
                    box-shadow: 0 0 0 rgba(0,0,0,0.5);
                  }
                }
              }
            }
          }
        }
        &:hover {
          z-index: 2;
          a, .a {
            &:after {
              width: 100%;
              height: 100%;
            }
            &:before {
              opacity: 1;
            }
          }
        }
        &:last-of-type {
          a {
            &:after {
              width: 100%;
              height: 100%;
              background: rgba(255,255,255,.16);
            }
            &:before {
              content: '';
              background: rgba(0,0,0,0.2);
              position: absolute;
              top: 0 !important;
              bottom: 0;
              right: 0;
              left: 0;
              margin: auto;
              transition: all 0.5s;
              border-radius: $a50;
              z-index: -1;
              width: 0%;
              height: 0%;
            }
          }
          &:hover {
            a {
              &:before {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .languge {
    position: relative;
    margin-left: auto;
    margin-right: $a25;
    border-radius: 50%;
    width: $a50;
    height: $a50;
    &:hover {
      li {
        &:first-of-type {
          top: 100%;
          left: -$a32;
          right: auto;
          padding-top: $a5;
        }
        &:last-of-type {
          top: 100%;
          padding-top: $a5;
          left: auto;
          right: -$a32;
        }
      }
    }
    li {
      display: flex;
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
      transition: all 0.5s;
      &:first-of-type {
        right: auto;
        left: 0;
      }
      &:last-of-type {
        left: auto;
        right: 0;
      }
      &.active {
        z-index: 1;
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      img, picture {
        width: 100%;
      }
    }
  }
  .burger {
    width: $a50;
    height: $a50;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      box-sizing: border-box;
      background: rgba(255,255,255,.16);
    }
    span {
      width: 50%;
      height: $a2;
      min-height: $a2;
      background: #ffffff;
      transition: all 0.5s;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      &:nth-child(1) {
        bottom: $a12;
      }
      &:nth-child(3) {
        top: $a12;
      }
    }
    &:hover {
      div {
        box-shadow: 0 0 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &.offset {
    padding: $a15 $a75;
    width: 100%;
    background: #fff;
    box-shadow: 0 $a2 $a4 0 rgba(0,0,0,0.1);
    border: none;
    .logo {
      background-image: url(/img/logo_color_black.png);
    }
    .menu {
      li {
        a, .a {
          color: #5d5c65;
          font-weight: 600;
        }
        &:last-of-type {
          a {
            color: #ffffff;
            font-weight: 400;
            &:after {
              background: $osn-col1;
            }
          }
        }
        .open {
          top: calc(100% + #{-$a10});
        }
        &:hover {
          a, .a {
            &:before {
              filter: drop-shadow(0 -$a1 $a1 rgba(0,0,0,.2));
              top: 112%;
            }
          }
        }
      }
    }
    .burger {
      div {
        background: $osn-col1;
      }
      &:hover {
        div {

        }
      }
    }
  }
  &.open {
    width: $a1180;
    padding: $a15 $a1 $a15 0;
    background: transparent;
    box-shadow: 0 0 0;
    &:before {
      border-bottom: $a2 solid rgba(255,255,255,0.1);
      border-top-width: $a2;
    }
    .menu {
      opacity: 0;
      transition-delay: 0s;
    }
    .logo {
      background-image: url(/img/logo_color.png) !important;
      height: $a52;
      padding-right: $a2;
    }
    .burger {
      position: relative;
      height: $a50;
      min-height: $a50;
      width: $a50;
      min-width: $a50;
      div {
        background: rgba(0,0,0,.2) !important;
      }
      span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto !important;
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(135deg);
        }
        &:nth-child(3) {
          transform: rotate(135deg);
          opacity: 0;
        }
      }
    }
    .languge {
      width: $a50;
      min-height: $a50;
      height: $a50;
      min-width: $a50;
      margin-right: $a25;
    }
  }
}


@media screen and (max-width: $a1150) {
  header {
    width: 100%;
    padding: $a10;
    .logo {
      height: $a35;
    }
    nav {
      .menu {
        li {
          margin: 0;
          .open {
            &:before {
              left: 23%;
            }
            .holder {
              .holder_item {
                max-width: 100%;
                .item {
                  max-width: 33%;
                }
              }
            }
          }
          &.markets {
            .open {
              &:before {
                left: 34%;
              }
            }
          }
        }
      }
    }
    &.offset {
      padding: $a10;
    }
  }
}

@media screen and (max-width: $mobi_start) {
  header {
    width: 100% !important;
    height: auto;
    padding: $m8 !important;
    transition: background 0.5s;
    &:before {
      transition-delay: 0s;
      transition: all 0s;
    }
    nav {
      display: none;
    }

    .logo {
      height: $m24;
    }

    .languge {
      width: $m25;
      height: $m25;
      margin-right: $m10;
      &:hover {
        li {
          &:first-of-type {
            left: -$m15;
            padding-right: $m15;
            padding-top: $m3;
          }
          &:last-of-type {
            padding-top: $m3;
            padding-left: $m15;
            right: -$m15;
          }
        }
      }
    }

    .burger {
      width: $m25;
      height: $m25;

      div {
        span {
          height: $a1;
          min-height: $a1;
          &:nth-child(1) {
            bottom: $m8;
          }

          &:nth-child(3) {
            top: $m8;
          }
        }
      }
    }

    &.open {
      width: 50% !important;
      transition-property: width;
      transition-delay: .5s;
      left: auto;
      &:before {
        transform: translateX(-75%);
        transition-delay: 0s;
        border-bottom-width: $a1;
      }
      .logo {
        opacity: 0;
        position: fixed;
        left: $m8;
      }
      .languge {
        width: $m25;
        height: $m25;
        margin-right: $m10;
      }
      .burger {
        width: $m25;
        height: $m25;
        div {
          span {
            bottom: 0;
          }
        }
      }
    }
  }
}
