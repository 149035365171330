$osn-col1: #45a3e5;
$osn-col2: #07efc3;
$osn-col3: #38c96c;
$shaded: #f6f8fa;
$mobi_start: 1023px;

@import "pxTOvw";
@import "_animate.scss";
@import "header";
@import "footer";
@import "load";
@import "big_menu";


::-webkit-scrollbar {
  width: $a10;
}
::-webkit-scrollbar-track {
  background: #121212;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, $osn-col1 0%, $osn-col2 100%);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: linear-gradient(0deg, $osn-col1 0%, $osn-col2 100%);
}
::-webkit-input-placeholder {color: #32313c;}
::-moz-placeholder          {color: #32313c;}
::-ms-input-placeholder      {color: #32313c;}
:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
}

::selection {
  background: #34b662;
  color: #fff;
}
::-moz-selection {
  background: #34b662;
  color: #fff;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: transparent;
}
input:focus::-moz-placeholder, textarea:focus::-moz-placeholder { /* Firefox 19+ */
  color: transparent;
}
input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder { /* IE 10+ */
  color: transparent;
}
input:focus:-moz-placeholder, textarea:focus:-moz-placeholder { /* Firefox 18- */
  color: transparent;
}

input, textarea {
  font-family: 'Montserrat', serif;
}

* {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  backface-visibility: visible;
}

html {
  position: relative;
  scrollbar-color: $osn-col1 #121212;
}

.wow {
  visibility: hidden;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: $a16;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  * {
    transform-style: flat;
  }
  &.page-load {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  &.off-scroll {
    overflow: hidden;
    margin-right: $a10;
  }
  &.scrollDown {
    header {
      transform: translateY(-100%);
    }
  }
}

picture {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}

p {
  margin: 0;
  padding: 0;
  white-space: pre-line;
  line-height: 1.5;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  &:focus, &:hover {
    text-decoration: none;
  }
}

button {
  border: none;
  cursor: pointer;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 800;
  white-space: pre-line;
  margin: 0;
  padding: 0;
}

svg {
  overflow: visible;
}

.lastAllowString {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.container {
  width: $a1220;
  box-sizing: border-box;
  padding-left: $a20 !important;
  padding-right: $a20 !important;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .line_holder {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding-left: $a20 !important;
    padding-right: $a20 !important;
    box-sizing: border-box;
    .line {
      height: 100%;
      width: $a1;
      background: rgba(255,255,255,0.2);
    }
  }
}

.svg_gradient {
  position: fixed;
  right: 100%;
  top: 0;
  width: 0;
  height: 0;
  #grad {
    .stop1 {
      stop-color: $osn-col1;
    }
    .stop2 {
      stop-color: $osn-col2;
    }
  }
}

svg.svg_bg_grad {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: $a50;
  width: 100%;
  height: 100%;
  z-index: -1;
  .svg_bg {
    fill: transparent;
    stroke: url(#grad);
    stroke-width: 45%;
  }
}

h2 {
  font-weight: 800;
  font-size: $a35;
  color: #32313c;
  text-align: center;
}

.h2_line {
  width: $a90;
  height: $a5;
  margin-top: $a10;
  border-radius: $a50;
  overflow: hidden;
  .line1 {
    width: 60%;
  }
  .line2 {
    width: 30%;
    x: 70%;
    z-index: 10;
  }
}

.h2_under {
  text-align: center;
  padding: $a20 0;
}

h3 {
  font-size: $a25;
  font-weight: bold;
  color: #000000;
}

h4 {
  font-size: $a18;
  font-weight: bold;
  line-height: initial;
  color: #000000;
}

.button1 {
  background-image: linear-gradient(62deg, rgb(52,182,98) 0%, rgb(57,206,110) 100%);
  box-shadow: -$a2 $a11 $a18 0 rgba(57, 206, 110, 0.27);
  border-radius: $a50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $a40;
  height: $a60;
  width: fit-content;
  box-sizing: border-box;
  min-width: $a229;
  font-size: $a16;
  font-weight: 500;
  position: relative;
  border: $a1 solid #39cc6d;;
  z-index: 1;
  transition: all 0.5s;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: $a50;
    width: 0;
    height: 0;
    background: #ffffff;
    transition: all 0.5s;
    content: '';
    z-index: -1;
    border: $a1 solid #39cc6d;
  }
  &:hover {
    color: #000000;
    &:before {
      width: 104%;
      height: 104%;
    }
  }
  &.reverse{
    background: transparent;
    border-color: $osn-col3;
    color: $osn-col3;
    overflow: hidden;
    &:before {
      background-image: linear-gradient(62deg, rgb(52,182,98) 0%, rgb(57,206,110) 100%);
      border: 0;
    }
    &:hover {
      color: #ffffff;
    }
  }
  &.arrow {
    text-align: left;
    justify-content: flex-start;
    padding: 0 $a20;
    box-sizing: border-box;
    height: $a50;
    width: min-content;
    box-shadow: none;
    white-space: nowrap;
    .arrow {
      height: 100%;
      position: relative;
      top: 0;
      bottom: 0;
      left: $a15;
      margin-left: $a15;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      transition: all 0.5s;
      &:after {
        width: $a13;
        height: $a13;
        border: $a1 solid $osn-col3;
        margin-right: $a15;
        border-bottom-color: transparent;
        border-left-color: transparent;
        transform: rotate(45deg) translateX(-25%) translateY(25%);
        content: '';
        transition: all 0.5s;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: $a19;
        width: $a25;
        height: $a1;
        background: $osn-col3;
        transition: all 0.5s;
      }
    }
    &:hover {
      .arrow {
        &:after {
          border-color: #fff;
          border-bottom-color: transparent;
          border-left-color: transparent;
        }
        &:before {
          background: #ffffff;
        }
      }
    }
    &.circle {
      color: #000000;
      font-size: $a20;
      margin-left: auto;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      transition: all 0.5s;
      box-sizing: border-box;
      padding: 0 $a62 0 0;
      background: none;
      border: none;
      box-shadow: none;
      &:before {
        display: none;
      }
      .arrow {
        border-radius: 50%;
        border: $a1 solid #000;
        right: 0;
        left : auto;
        width : $a50;
        position: absolute;
        &:after {
          border-color: #000000;
          border-bottom-color: transparent;
          border-left-color: transparent;
        }
        &:before {
          display: none;
        }
      }
      &:hover {
        .arrow {
          width: calc(100% + #{$a20});
          border-radius: $a50;
        }
      }
    }
  }
  &.white {
    box-shadow: -$a2 $a11 $a18 0px rgba(45, 138, 136, 0.27);
    background: transparent;
    border-color: #ffffff;
    color: $osn-col1;
    overflow: hidden;
    &:before {
      border: $a30 solid #fff;
      border-left-width: $a135;
      border-right-width: $a135;
      border-radius: 50%;
      width: 101%;
      height: 200%;
      background: transparent;
      box-sizing: border-box;
    }
    &:hover {
      color: #ffffff;
      &:before {
        border-width: 0px;
        width: 101%;
      }
    }
  }
}

.button2 {
  @extend .button1;
  &:before {
    border: $a1 solid $osn-col1;
  }
  border-color: $osn-col1;
  background-image: linear-gradient(62deg, rgb(60,145,204) 0%, rgb(69,163,229) 100%);
  box-shadow: -$a2 $a11 $a18 0px rgba(69, 163, 229, 0.27);
}

.perspective {
  perspective: $a1900;
  will-change: transform;
  .cube {
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    transition: all 0.5s;
    width: 100%;
    height: 100%;
    will-change: transform;
    .side {
      transition: all 0.5s;
      transition-delay: 0s;
      width: 100%;
      height: 100%;
      will-change: transform;
      transform: translateZ($a100) scale(.95);
      &:nth-of-type(2) {
        transform: rotateY(270deg) translateZ($a100) scale(.95);
        position: absolute;
        opacity: 0;
        transition: all 0.3s;
        transition-delay: 0.3s;
      }
    }
    &.vert {
      .side {
        transition: all 0.5s;
        transition-delay: 0s;
        width: 100%;
        transform: translateZ($a100);
        &:nth-of-type(2) {
          transform: rotateX(270deg) translateZ($a100);
          position: absolute;
          opacity: 0;
          transition: all 0.3s;
          transition-delay: 0.3s;
        }
      }
    }
  }
  &:hover {
    .cube {
      transform: rotateY(90deg);
      .side {
        opacity: 0;
        transition-delay: 0.3s;
        &:nth-of-type(2) {
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  }
}

main {
  position: relative;
  z-index: 3;
  overflow: hidden;
  padding-bottom: $a20;
  background-color: transparent;
}

@media screen and (max-width: $mobi_start) {
  main {
    padding-bottom: $m15;
  }
  body {

    &.off-scroll {
      margin: 0;
    }
  }
  .container {
    width: 95%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box;
    .line_holder {
      padding: 0 !important;
    }
  }
  h2 {
    font-size: $m22;
    text-align: center !important;
    padding: 0 $m5;
  }
  h3 {
    font-size: $m20;
  }
  h4 {
    font-size: $m18;
  }
  .h2_line {
    width: $m88;
    height: auto;
    margin: $m10 0  $m20 0!important;
  }
  .h2_under {
    width: 95%;
    margin: 0 auto;
    padding: 0;

  }
  p {
    font-size: $m12 !important;
    white-space: normal !important;
    text-align: justify !important;
    line-height: $m20 !important;
    hyphens: auto;
  }
  .button1, .button2 {
    width: $m190;
    min-width: auto;
    padding: 0;
    height: $m50;
    font-size: $m14;
    box-shadow: $m2 $m5 $m9 0 rgba(57, 206, 110, 0.27);
    border-radius: $m50;
    &.white {
      &:before {
        border-left-width: $m96;
        border-right-width: $m96;
      }
    }
    &.arrow {
      &.circle {
        width: auto;
        height: $m30;
        font-size: $m14;
        padding-right: $m40;
        .arrow {
          width: $m30;
          &:after {
            margin-right: $m8;
            width: $m10;
            height: $m10;
          }
        }
        &:hover {
          padding-left: $m10;
          .arrow {
            width: 100%;
          }
        }
      }
    }
  }
  .slick-dots {
    display: flex;
    justify-content: center;
    position: static;
    padding: $m20 0;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 $m9;
      button {
        font-size: 0;
        padding: $m2;
        min-width: $m20;
        min-height: $m20;
        position: relative;
        border-width: $a1;
        border-color: rgb(233, 240, 244);
        border-style: solid;
        border-radius: 50%;
        &:before {
          display: none;
        }
        &:after {
          content: '';
          border-radius: 50%;
          background: rgb(233, 240, 244);
          width: 100%;
          height: 100%;
          display: flex;
          z-index: 1;
        }
      }
      &.slick-active {
        opacity: 1;
        button {
          &:before {
            opacity: 1;
          }
          &:after {
            background-image: linear-gradient(62deg, rgb(52, 182, 98) 0%, rgb(57, 206, 110) 100%);
            box-shadow: 0 $m3 $m13 0 rgba(57, 206, 110, 0.52);
          }
        }
      }
    }
  }
}
